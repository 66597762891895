.profile {
  padding: 0 !important;
  position: relative;
  @include media-breakpoint-down(md) {
    margin: 0 0 45px 0 !important;
  }
}

.gmp-profile {
  padding: 0 !important;
  position: relative;
  @include media-breakpoint-down(md) {
    margin: 0 0 0px 0 !important;
  }
}
.profile-content {
  // padding-right: 0px !important;
  // padding-left: 0px !important;
  @include media-breakpoint-down(md) {
    text-align: left;
  }
  .btn-transaction {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  // .profile-bg {
  //   background: url("../img/banner.png");
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   height: 200px;
  //   position: relative;
  //   margin-bottom: 20px;
  //   width: 100%;
  // }
  .profile-image-header {
    position: absolute;
    z-index: 0;
    width: 140px;
    transform: translateX(-50%);
    left: 50%;
    top: 40px;
    height: auto;
    padding-top: 40px;
    .rounded-circle {
      border: 1px solid #eee;
    }
    @include media-breakpoint-down(md) {
      width: 80px;
      margin: 0 auto;
      padding-bottom: 30px;
    }
    img {
      width: 140px;
      height: 140px;
      object-fit: cover;
      @include media-breakpoint-down(md) {
        width: 80px !important;
        height: 80px !important;
        object-fit: cover;
      }
    }
  }

  .profile-content {
    color: #000;
    text-align: center;
  }

  .profile-name {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    @include media-breakpoint-down(md) {
      font-size: 20px;
      color: #fff;
    }
    @media (min-width: 768px) and (max-width: 1079.98px) {
      font-size: 20px;
    }
  }
  .profile-email {
    font-size: 14px;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .profile-contact {
    font-size: 13px;
  }
}

.table-title {
  font-size: 13px;
  width: 35%;
}

@media (min-width: 768px) and (max-width: 1079.98px) {
  .table-title {
    font-size: 12px;
  }
}

.select-pager {
  width: 35%;
  @include media-breakpoint-down(sm) {
    width: 35%;
  }
}

.profile-banner {
  // position: relative;
  background: url("../../../assets/img/img-portal-banner-smart.jpg") no-repeat
    50%;
  background-size: cover;
  background-position: buttom;
  width: auto;
  height: 200px;
  transition: all 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 16px;

  @include media-breakpoint-down(lg) {
    margin: 0 8px;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
  }
  .icon-logo {
    fill: white;
    height: 50px;
  }
  .bottom-align-text {
    padding: 12px 0;
    // position: absolute;
    // bottom: 25px;
    // left: 25px;
  }
}

.tabs-container {
  display: flex;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.tabs-container-business {
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.tabs-container-mobile {
  display: block;
}

.tab-list-container {
  padding-left: 0;
  width: 30%;
  border-right: 1px solid grey;
  
  li {
    list-style-type: none;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    border-right: unset;
  }
}

.tab-list-container-mobile {
  padding-left: 0;
  width: 30%;
  border-right: 1px solid grey;
  margin: 0;

  li {
    // &:hover,
    // &:active {
    //   background: none !important;
    //   outline: none !important;
    // }
    // &:focus {
    //   outline: none !important;
    //   box-shadow: none !important;
    // }
    white-space: nowrap;
    list-style-type: none;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    display: flex;
    overflow-y: auto;
    // white-space: nowrap;
    border-right: unset;
    line-height: normal;
  }
}

.profile-title {
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  padding: 5px 15px;
}

.profile-title-mobile {
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #4c6085;
  opacity: 1;
}

.tab-panel-container {
  margin-left: 30px;
  width: 70%;
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-left: 0;
  }
}
.complete-profile-info .react-tabs__tab {
  cursor: pointer;
  margin: 5px;
  padding: 5px 5px;
  font: normal normal Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #454545;
  &--selected {
    color: #6fafe4 !important;
    background: #f2f7fe !important;
    // &:after {
    //   position: absolute;
    //   content: "";
    //   border-bottom: 1px solid yellow;
    //   bottom: 0;
    //   width: 70%;
    //   left: 0;
    //   transform: translateX(20%);
    // }
  }
}

// .complete-profile-info .react-tabs__tab {
//   cursor: pointer;
//   margin: 5px;
//   padding: 5px 15px;
//   font-weight: bold;
//   &--selected {
//   color: #6fafe4;
//   background: #f2f7fe;
//   position: relative;
//   &:after {
//   position: absolute;
//   content: "";
//   border-bottom: 1px solid yellow;
//   bottom: 0;
//   width: 70%;
//   left: 0;
//   transform: translateX(20%);
//   }
//   }
//  }

.mobile-profile-info .react-tabs__tab {
  font: normal normal normal 12px/18px Poppins !important;
  color: #9fa2a7 !important;
  background: none !important;
  &--selected {
    color: #454545 !important;
    background: none !important;
    outline: none !important;
    div.profile-under {
      border-bottom: 1px solid #5272aa !important;
      border-bottom-width: 2px !important;
      width: 50% !important;
      margin-left: 30px !important;
    }
    div.password-under {
      border-bottom: 1px solid #5272aa !important;
      border-bottom-width: 2px !important;
      width: 50% !important;
      margin-left: 15px !important;
    }
  }
}

.nav-header-list {
  padding: 10px 30px;
}

.nav-header-list.active {
  border-bottom: 5px solid #6fafe4;
  // span {
  //   color: #6fafe4;
  // }
}

.nav-icons {
  svg,
  img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
}

.btn-nav-icons {
  @include media-breakpoint-down(sm) {
    width: 80%;
  }
}

.nav-wrapper-links {
  a {
    text-decoration: none;
    color: #000;
  }
  position: relative;
  text-emphasis: none;
  &:hover {
    text-align: none;
  }
  &::before {
    height: 1px;
    width: 100%;
    content: "";
    position: absolute;
    bottom: 1px;
    border-bottom: 1px solid grey;
  }
}
// d-flex flex-column flex-lg-row  bg-white p-1 mt-1 position-relative
.inside-videos-list-item {
  display: flex;
  width: 100%;
  background: white;
  position: relative;
  padding: 5px;
  margin: 5px;
  @media (max-width: 1300px) {
    display: block;
    width: 100%;
  }
}

.inside-videos-list-img {
  width: 120px;
  height: 80px;
  object-fit: cover;
  @media (max-width: 1300px) {
    width: 100%;
    height: 100px;
    // object-fit: cover;
  }
  @media (max-width: 768px) {
    height: 200px;
    // object-fit: cover;
  }
}

.inside-videos-content {
  // d-flex flex-column px-2 py-2 py-lg-0
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  @media (max-width: 1300px) {
    display: block !important;
    padding: unset;
    margin: 5px 0;
  }
}

.player-outlay {
  margin-left: 30px;
  margin-top: 10px;
  width: 60px;
  height: 60px;
  opacity: 0.5;

  @media (max-width: 1300px) {
    display: none !important;
  }
}

.transac-paginate {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.inside-latest-news-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  @media (max-width: 1300px) {
    width: 100%;
    height: 200px;
  }
}

.list-link {
  width: 30%;
  height: 150px;
  @media (max-width: 1300px) {
    width: 100%;
    height: 250px;
  }
}

.videos-link {
  text-decoration: none;
  color: unset;
  &:hover {
    text-decoration: none;
    color: grey;
  }
}

.inside-latest-news-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  p {
    text-align: justify;
  }
  @media (max-width: 1300px) {
    width: 100%;
    padding: unset;
  }
}

.inside-latest-news-list {
  display: flex;
  background: white;
  padding: 10px;
  margin-top: 25px;
  @media (max-width: 1300px) {
    display: block;
  }
}

.latest-img {
  width: 100%;
  object-fit: contain;
  height: 350px;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}

// .gmp-profile-content-inside {
//   display: flex;
//   align-items: center;

//   @include media-breakpoint-down(md) {
//     display: flex;
//     width: 100%;
//     margin: 0 auto;
//     justify-content: center;
//     align-items: flex-end;
//     background: rgb(119, 144, 203);
//     background: linear-gradient(
//       180deg,
//       rgba(119, 144, 203, 1) 0%,
//       rgba(149, 176, 226, 1) 50%,
//       rgba(177, 205, 248, 1) 100%
//     );
//   }
// }

// .profile-content-inside {
//   display: flex;
//   align-items: center;

//   @include media-breakpoint-down(md) {
//     display: flex;
//     width: 100%;
//     margin: 0 auto;
//     justify-content: center;
//     align-items: flex-end;
//     background: rgb(119, 144, 203);
//     padding-bottom: 70px !important;
//     background: linear-gradient(
//       180deg,
//       rgba(119, 144, 203, 1) 0%,
//       rgba(149, 176, 226, 1) 50%,
//       rgba(177, 205, 248, 1) 100%
//     );
//   }
// }

.profile-links {
  margin-top: 10px;
  .profile-links--item {
    .profile-link--span {
      color: #4c6085;
      font: normal normal normal 12px/18px Poppins;
      padding: 10px;
    }
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 58px;
    height: 24px;
    padding: 3px;
  }
  .active {
    .profile-link--span {
      color: #ffffff;
      font: normal normal normal 12px/18px Poppins;
      padding: 10px;
    }
    background: #5272aa 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 58px;
    height: 24px;
    padding: 5px;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
  color: #fff;
  &--item {
    color: #fff;
    margin: 5px 7px 0px 0px;
    font-size: 14px;
  }
}

.label-address {
  font-size: 15px !important;
  padding: 0 !important;
  margin: 10px;
  text-align: center;
}

// .profile-links .active {
//   // border-bottom: 2px solid white;
//   // font-weight: bold;
//   background: #5272aa 0% 0% no-repeat padding-box;
//   border-radius: 12px;
// }

.profile-content .profile-image {
  width: 80px;
  margin-right: 10px;
  @include media-breakpoint-down(md) {
    width: 80px;
    margin: 0 auto;
  }
}

.profile-inside-content {
  display: flex;
  align-items: center;
  .profile-content {
    @include media-breakpoint-down(md) {
      margin: 0 auto;
      padding-left: 10px;
      text-align: left;
    }
  }
}

.profile-inside-image {
  @include media-breakpoint-down(sm) {
    padding-right: 0.5rem;
  }
}

.general-spacer-mobile {
  padding: 0;
  margin: 0 auto;
  min-height: 800px;
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
    padding: 0 !important;
  }
}

.sider-col {
  @include media-breakpoint-up(md) {
    padding-left: 20px;
  }
}

.mobile-row {
  @include media-breakpoint-down(sm) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.mobile-row-inner {
  @include media-breakpoint-down(sm) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.button-timer-container {
  display: block;
}

.l-enews-ptop {
  padding-top: 34px;
  border-bottom: 1px solid #65676b;
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #fff;
  }
}

.main-links-inside {
  color: #65676b;
  &:hover {
    color: #5ea5e0 !important;
  }
  i {
    color: #65676b;
    &:hover {
      color: #5ea5e0 !important;
    }
  }
}

.main-links-inside.selected {
  color: #5ea5e0 !important;
  i {
    color: #5ea5e0 !important;
  }
}

.main-inside-link-list {
  display: flex;
  margin-left: 20px;
  align-items: center;
  padding: 5px 0;
  span a {
    color: black;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
}

.sub-h3 {
  font-size: 12px !important;
  font-weight: 300 !important;
}

.main-service-button-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  @include media-breakpoint-down(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.main-service-button-item {
  width: 100%;
  margin-top: 10px;
  @include media-breakpoint-down(sm) {
    width: 45%;
    margin: 5px;
  }
  button {
    &:hover {
      text-decoration: none;
    }
    text-decoration: none;
    -webkit-box-shadow: 2px 2px 6px #00000014;
    -moz-box-shadow: 2px 2px 6px #00000014;
    box-shadow: 2px 2px 6px #00000014;
    border-radius: 10px;
    color: #4c6085 !important;
    display: flex;
    width: 100%;
    align-items: center;
    .msb-icon-wrapper {
      width: 20px;
      margin-right: 20px;
      font-size: 20px;
      @include media-breakpoint-down(sm) {
        margin: unset;
      }
    }
    .msb-title {
      width: 80%;
      font-size: 18px;
      text-align: left;
      @include media-breakpoint-down(sm) {
        font-size: 15px;
        width: 100%;
      }
    }
    .msb-span {
      color: #9fa2a7;
      font-size: 12px;
      font-weight: 400;
    }
    .msb-title-main {
      font-weight: bold;
      font-size: 18px;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      flex: 1;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;
      min-height: 120px;
      padding: 15px 5px;
    }
  }
}

.latest-videos-main-wrapper {
  max-height: 572px;
  min-height: 572px;
  overflow-x: hidden;
  overflow-y: auto;
}

.widget-wrapper {
  @include media-breakpoint-down(md) {
    position: absolute;
    width: 85%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
  }
}

.edit-button-title {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #5272aa;
  font-weight: 400;
}

.edit-button-value {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #9fa2a7;
  opacity: 1;
}

.button-edit-icon {
  background: #4c6085;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  color: $white;
  font-size: 10px;
}

.edit-button-title-web {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #4c6085;
  font-weight: bolder;
}

.edit-button-value-web {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.id-wrapper {
  background: $color-primary;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
}

.id-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }
}

.id-name {
  text-align: right;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.id_name {
  font-size: 40px;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

.table-id {
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.profile-tabs-mobile {
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: 20px;
  .profile-tabs {
    .profile-tab-item {
      width: 58px !important;
      height: 24px !important;
    }
    font: normal normal normal 12px/18px Poppins !important;
    color: $color-primary;
    background: $alice-blue;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    border-radius: 5px;
    text-align: center;
  }
  .active {
    .profile-tab-item {
      width: 58px !important;
      height: 24px !important;
    }
    color: #fff;
    background: $color-primary;
    outline: none !important;
    padding: 16px 10px;
    border-radius: 5px;
    width: 58px !important;
    height: 24px !important;
  }
}
