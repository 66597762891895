$small-base-line-height: 70px;
$base-line-height: 70px;
$white: rgb(255, 255, 255);
$off-white: rgba($color-primary, 1);
$spin-duration: 0.5s;
$pulse-duration: 750ms;
$icon-height: 30px;

.full-loader-wrapper {
  // background: rgba($color-primary, 1);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.loading-circle {
  position: relative;
  width: $base-line-height;
  height: $base-line-height;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border: 0.2rem solid $off-white;
    border-top-color: $white;
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    animation: spin $spin-duration infinite linear;
  }
  .icon-logo-loader {
    padding: 15px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inner-loader-wrapper {
  position: relative;
  left: calc(50% - #{$base-line-height} / 2);
  top: calc(50% - #{$base-line-height} / 2);
  display: inline-block;
  background: white;
  padding: 5px;
}
.loading-icon {
  position: absolute;
  left: 15px;
  top: 15px;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
}

.mini-loader-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .loading-circle {
    align-self: center;
    width: $small-base-line-height;
    height: $small-base-line-height;
    border: 0.2rem solid $white;
    // border-top-color: $off-white;
    margin: 20px auto;
    display: inline-block;
  }
}

.container-loader {
  background: rgba($color-primary, 1);
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  &-wrapper {
    display: inline-block;
    background: white;
    padding: 5px;
  }
}
