.user-photo {
  width: 48px;
  border-radius: 6px;
  overflow: hidden;
  &.small {
    width: 32px;
    height: 32px;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-dropdown {
  font-size: 14px;
}
