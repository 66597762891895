.business-content .react-tabs {
    &__tab-list {
      display: flex;
      text-decoration: none;
      list-style-type: none;
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
    &__tab {
      margin-right: 5px;
      cursor: pointer;
      background: #f4f4f4;
      color: #9fa2a7;
      padding: 10px 15px;
      border-radius: 5px 5px 0 0;
      font-weight: bold;
      &--selected {
        background: #4c6085;
        color: #fff;
      }
    }
    &__tab-panel {
      &--selected {
        border: 1px solid #5272aa;
        border-radius: 0 10px 10px 10px;
      }
    }
  }
