.brand-logo {
  width: 150px;
  height: 84px;
  &.white {
    fill: $white;
  }
  &.small {
    width: 72px;
    height: 40px;
  }
}
