// Mixins

// Reset List
//
// Resets default list styling.
//
// @usage - `@include reset-list`
//
// Styleguide 10.1

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Hovers
//
// Create pseduo-classes for `:hover`, `:active`, and `:focus`.
//
// @usage - `@include hover`
//
// Styleguide 10.3

@mixin hover {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

// Feature Detection
//
// $feature - Feature-specific class, i.e. `cssgradients`.
// @usage - `@include supported($feature)`
// @usage - `@include not-supported($feature)`

@mixin supported($feature) {
  .#{$feature} & {
    @content;
  }
}

@mixin not-supported($feature) {
  .no-js &,
  .no-#{$feature} & {
    @content;
  }
}

// Media Queries
//
// Allows you to use inline media queries. Includes options for `$fix-mqs`.
//
// $breakpoint - Desired breakpoint. Can be variable or unit.
// $query - Defaults to `min-width`.
// $type - Defaults to `screen`.

@mixin mq($breakpoint, $query: "min-width", $type: "screen") {
  @if $fix-mqs {
    @if $fix-mqs >= $breakpoint {
      @content;
    }
  } @else {
    @media #{$type} and (#{$query}: #{$breakpoint}) {
      @content;
    }
  }
}

// Clearfix
//
// Nicolas Gallagher's micro clearfix hack
//
// @source - http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// Hide Text
//
// @usage - `@include hide-text`

@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

// Hidden
//
// Hide from both screenreaders and browsers
//
// @usage - `@include hidden`

@mixin hidden {
  display: none !important;
  visibility: hidden;
}

// Visually Hidden
//
// Hide only visually, but have it available for screenreaders
//
// @usage - `@include visuallyhidden`

@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

// Invisible
//
// Hide visually and from screenreaders, but maintain layout
//
// @usage - `@include invisible`

@mixin invisible {
  visibility: hidden;
}

@mixin border-blur {
  -webkit-box-shadow: 0px 0px 11px 0px rgba(76, 96, 133, 0.21);
  -moz-box-shadow: 0px 0px 11px 0px rgba(76, 96, 133, 0.21);
  box-shadow: 0px 0px 11px 0px rgba(76, 96, 133, 0.21);
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
