.doctors {
  padding: 10px;
  @include border-blur;
  //   overflow: hidden;
  margin: 10px;
  border-radius: 15px;
  width: 185px;
  &:hover {
    @include border-blur;
    text-decoration: none;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  &-contents {
    font-size: 12px;
  }
  &-image {
    display: flex;
  }
  &-image img {
    margin: 10px auto;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  &-title {
    font-size: 14px;
    font-weight: bold;
  }
  &-rating {
    i {
      color: yellow;
    }
    span {
      font-size: 12px;
    }
  }
  &-tags {
    color: rgb(161, 161, 161);
  }
  &-description {
    margin-top: 10px;
    color: rgb(90, 90, 90);
  }
  &-select {
    width: 50%;
    margin: 0px auto 20px;
    @include media-breakpoint-down(sm) {
      width: 80%;
    }
  }
}
