.history-table {
  color: $dark-blue;
  font-size: 12px !important;
  min-height: 500px;
  @media (max-width: 600px){
    font-size: 14px !important;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #4c6085;
  }
  table {
    position: relative;
    thead {
      color: $black;
    }
    td {
      padding: 15px 5px;
      word-wrap: break-word;
    }
    .td-header {
      white-space: nowrap;
    }
    .td-item {
      // max-width: 30%;
      max-width: 100px;
    }
  }
  .long-bar {
    width: 100%;
    position: absolute;
    background: #d9d9d9;
    height: 1px;
    padding: 0px;
  }

  .paid-icon {
    color: $white;
    background-color: $color-primary;
    font-weight: 600;
    border-radius: 5px;
    padding: 3px 8px;
  }
  .pending-icon {
    color: #A6A6A6;
    background-color: #D8D8D8;
    font-weight: 600;
    border-radius: 5px;
    padding: 3px 8px;
  }

}