.arrow-card {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  .arrow-cell {
    border-bottom: 1px solid #d9d9d9;
    color: $black;
    position: relative;
    transition: 200ms ease-in-out;
    &:hover {
      color: $color-primary;
      transition: 200ms ease-in-out;
    }
    &.active {
      color: $white;
      background: $color-secondary;
    }
    .arrow {
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 20%;
      right: -20px;
      position: absolute;
      box-sizing: border-box;

      border: 0.7em solid $color-primary;
      border-color: $color-primary;

      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: 1px -1px 1px 0px $color-primary;
    }
    .arrow-v2 {
      top: 2px;
      right: -50px;
      position: absolute;
      box-sizing: border-box;

      // border: 0.7em solid $color-primary;
      // border-color: $color-primary;

      // transform-origin: 0 0;
      // transform: rotate(45deg);
      // box-shadow: 1px -1px 1px 0px $color-primary;
    }
    .arrow-border {
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 20%;
      right: -19px;
      position: absolute;
      box-sizing: border-box;

      border: 0.7em solid $color-secondary;
      border-color: $color-secondary;

      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: 1px -1px 0px 0px $color-secondary;
    }
  }

  .label {
    color: $black;
  }
}

.arrow-card-ovrs {
  position: relative;
  box-sizing: border-box;
  .arrow-cell {
    border: none;
    color: white;
    position: relative;
    transition: 200ms ease-in-out;
    background: $color-primary;
    border-radius: 10px;
    &:hover {
      color: $white;
      background: $color-primary;
      transition: 200ms ease-in-out;
      border: $color-primary;
    }
    &.active {
      color: $white;
      background: $color-primary;
    }
    .arrow {
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 20%;
      right: -20px;
      position: absolute;
      box-sizing: border-box;

      border: 0.7em solid $color-primary;
      border-color: $color-primary;

      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: 1px -1px 1px 0px $color-primary;
    }
    .arrow-border {
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 20%;
      right: -19px;
      position: absolute;
      box-sizing: border-box;

      border: 0.7em solid $color-secondary;
      border-color: $color-secondary;

      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: 1px -1px 0px 0px $color-secondary;
    }
  }

  .label {
    color: $black;
  }
}

.upload-ovrs {
  width: 320px;
}

.arrow-card-business {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  .arrow-cell {
    border-bottom: 1px solid #d9d9d9;
    color: $black;
    position: relative;
    transition: 200ms ease-in-out;
    &:hover {
      color: $color-primary;
      transition: 200ms ease-in-out;
    }
    &.active {
      color: $white;
      background: $color-secondary;
    }
  }

  .label {
    color: $black;
  }
}

.requirements-mobile {
  margin-top: 5px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  // border-top: 2px solid $lighter-blue;
  border-radius: 10px;
  min-height: 70px;
  color: #151515;
  &.active-mobile {
    background: #4C6085;
    color: white;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 63px;
      right: -10px;
      top: 3px;
    }
  }
}
