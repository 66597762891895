.login-container-grp {
  display: none;
  button {
    width: 200px;
  }
  @include media-breakpoint-down(sm) {
    display: flex;
    padding-top: 110px;
    justify-content: center;
  }
}

.section {
  @include media-breakpoint-down(sm) {
    margin-bottom: 50px;
  }
  .section-content {
    padding: 110px 0 110px;
    display: flex;
    @include media-breakpoint-down(sm) {
      padding: 10px 0 0 !important;
    }
  }

  .services-list {
    .services-list-wrapper {
      @include border-blur;
      width: 98%;
      padding: 10px 20px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 10px 0;
        box-shadow: unset;
      }
    }
  }

  .news-list {
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
    .news-list-wrapper {
      @include border-blur;
      padding: 10px 20px;
    }
  }
}

.title-head {
  font-family: "Poppins";
  color: #4c6085;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 5px solid #4c6085;
}

.services-items-wrapper {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 300px;
  @include media-breakpoint-down(sm) {
    width: 100%;
    justify-content: space-around;
    // flex-direction: column;
  }
}

.services-items {
  text-align: center;
  width: 48%;
  padding: 20px;
  margin: 10px 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  @include border-blur;
  &:hover {
    background: #ecf4fa;
    text-decoration: none;
    cursor: pointer;
  }
  @include media-breakpoint-down(sm) {
    margin: 10px 5px;
    width: 45%;
    padding: 10px;
  }
}

.services-img-icon {
  width: 140px;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.services-title {
  font-size: 25px;
  font-weight: 600;
  color: #4c6085;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.services-description {
  color: #4c6085;
  @include media-breakpoint-down(sm) {
    font-size: 11px;
  }
}

.featured-news-img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.featured-news-title {
  overflow-wrap: break-word;
  font-weight: 800;
  color: #000;
  font-size: 25px;
  line-height: 27px;
  margin-top: 20px;
  padding: 0;
  a {
    text-decoration: none;
    color: unset;
  }
  // @include media-breakpoint-down(md) {
  //   line-height: 13px;
  // }
}

.featured-news-date {
  font-size: 12px;
  color: grey;
}

.news-item {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.news-banner {
  width: 50%;
  margin-right: 10px;
  img {
    height: 100px;
    width: 100%;
    object-fit: cover;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.news-body {
  width: 50%;
  margin-right: 5px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.news-body-title {
  font-weight: bold;
  font-size: 12px;
  overflow-wrap: break-word;
  // @include media-breakpoint-down(md) {
  //   line-height: 14px;
  // }
}

.news-body-date {
  color: grey;
  font-size: 11px;
}

.news-list-scroll {
  margin-top: 20px;
  overflow-y: auto;
  height: 450px;
  a {
    text-decoration: none;
    color: unset;
    &:hover {
      font-weight: bold;
    }
  }
}

.section-egov {
  height: 100%;
  margin-bottom: 100px;
  @include media-breakpoint-down(sm) {
    padding: 0 5px;
  }
}

.section-eservice {
  background: #ecf4fa;
  height: 100%;
  padding: 100px 0;
  margin-bottom: 100px;
  @include media-breakpoint-down(sm) {
    padding: 0 5px;
  }
}

.section-ebusiness {
  background: #ecf4fa;
  padding: 100px 0;
  margin-bottom: 70px;
  @include media-breakpoint-down(sm) {
    padding: 50px 5px;
  }
}

.section-ecommerce {
  padding: 100px 0;
  margin-bottom: 70px;
  @include media-breakpoint-down(sm) {
    padding: 50px 5px;
  }
}

.section-header-banner {
  text-align: center;
  margin-bottom: 50px;
  &-title {
    font-size: 35px;
    color: #4c6085;
    font-weight: bolder;
  }
}

.section-body {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  align-items: center;
  flex-wrap: no-wrap;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 50px;
  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0 25px;
    padding: 0;
  }
  @include media-breakpoint-down(md) {
    margin: 0 25px;
    padding: 0;
  }
}

.section-left {
  flex-basis: 50%;
  position: relative;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  margin-left: -120px;

  &--egov {
    order: 1;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
  }
  &--ebusiness {
    order: 2;
    margin-left: 0px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
  }
  &--ecommerce {
    order: 2;
    margin-left: 0;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
  }
  &--eservice {
    order: 1;
    margin-left: -100px;
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
  &--epayment {
    order: 1;
    margin-left: 30px;
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}

.section-left-epayment {
  flex-basis: 50%;
  position: relative;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  margin-left: 0px;
}

.section-right {
  flex-basis: 30%;
  order: 2;
  margin-left: 50px;
  margin-right: -100px;

  &--egov {
    margin-right: -170px;
  }
  &--ebusiness {
    order: 1;
    margin-left: 5%;
    margin-right: 0px;
    .service-wrapper-item {
      padding-right: 0%;
      // padding-left: 10%;
      @include media-breakpoint-down(sm) {
        padding-left: 0%;
      }
    }
  }
  &--epayment {
    order: 1;
    margin-right: 100px;
    .service-wrapper-item {
      padding-right: 0%;
      // padding-left: 10%;
      @include media-breakpoint-down(sm) {
        padding-left: 0%;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.epayment-css {
  // display: flex;
  align-items: center;
  background: #fafafa;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 10px 20px;
    justify-content: center;
    display: flex;
  }
  .service-wrap-img {
    width: 80px;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      width: 30%;
      margin: 0 auto;
    }
  }
  .service-wrap-body-title {
    font-size: 15px;
    color: #4c6085;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .service-wrap-body {
    width: 300px;
    font-size: 12px;
    color: grey;
    margin-top: 30px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      font-size: 14px;
      margin: 0 10px;
    }
  }
}

.service-wrapper-item {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  // border-radius: 0px 20px 0px 0px;
  opacity: 1;
  margin-top: 20px;
  padding: 15px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 10px 20px;
    justify-content: center;
    display: flex;
  }
  .service-wrap-img {
    width: 80px;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      width: 30%;
      margin: 0 auto;
    }
  }
  .service-wrap-body-title {
    font-size: 15px;
    color: #4c6085;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .service-wrap-body {
    width: 300px;
    font-size: 12px;
    color: grey;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      font-size: 14px;
      margin: 0 10px;
    }
  }
}

.service-wrapper-item-egov {
  display: flex;
  align-items: center;
  background: #fafafa;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 10px 20px;
    display: flex;
  }
  .service-wrap-img {
    width: 80px;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      width: 30%;
      margin: 0 auto;
    }
  }
  .service-wrap-body-title {
    font-size: 15px;
    color: #4c6085;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      width: 100%;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .service-wrap-body {
    width: 100%;
    font-size: 12px;
    color: grey;
    @include media-breakpoint-down(sm) {
      width: 100%;
      font-size: 14px;
      margin: 0 10px;
    }
  }
}

.service-wrapper-item-sa {
  display: flex;
  align-items: center;
  background: #fafafa;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 10px 20px;
    display: flex;
  }
  .service-wrap-img {
    width: 80px;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      width: 30%;
      margin: 0 auto;
    }
  }
  .service-wrap-body-title {
    font-size: 15px;
    color: #4c6085;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      width: 100%;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .service-wrap-body {
    width: 300px;
    font-size: 12px;
    color: grey;
    @include media-breakpoint-down(sm) {
      width: 100%;
      font-size: 14px;
      margin: 0 10px;
    }
  }
}

.section-right-smart-city {
  flex-basis: 50%;
  order: 2;
  margin-left: 50px;
  margin-right: -100px;
  &--ebusiness {
    order: 1;
    margin-left: 5%;
    margin-right: 0px;
    .service-wrapper-item {
      padding-right: 0%;
      // padding-left: 10%;
      @include media-breakpoint-down(sm) {
        padding-left: 0%;
      }
    }
  }
  &--epayment {
    order: 1;
    margin-right: 100px;
    .service-wrapper-item {
      padding-right: 0%;
      // padding-left: 10%;
      @include media-breakpoint-down(sm) {
        padding-left: 0%;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.default-img-wrapper {
  width: 100%;
  img {
    width: 100%;
  }
}

.scrollToTop {
  position: fixed;
  bottom: 10px;
  right: 50%;
  transform: translateX(50%);
  // @include media-breakpoint-down(sm) {
  //   right: 30px;
  // }
}

.scrollToTop-inner {
  position: fixed;
  bottom: 10px;
  right: 50%;
  transform: translateX(50%);
}

.btn-to-top {
  background: url("../../img/back-to-top.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 50px;
  height: 50px;
  border: none !important;
}

.chat-wrapper {
  display: block;
  position: fixed;
  bottom: 10px;
  right: 20px;
  // @include media-breakpoint-down(sm) {
  //   display: none;
  // }
}

.chat-img {
  background: url("../../img/chat-w-us.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 120px;
  height: 120px;
  border: none !important;

  @include media-breakpoint-down(sm) {
    width: 80px;
    height: 80px;
  }
}

.section-header-banner-sub {
  margin: 0 5px;
}

.comming-soon {
  width: 30%;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.meantime {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  p,
  ul {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.services-items-mobile {
  text-align: center;
  width: 48%;
  padding: 20px;
  margin: 10px 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  @include border-blur;
  &:hover {
    background: #ecf4fa;
    text-decoration: none;
    cursor: pointer;
  }
  @include media-breakpoint-down(sm) {
    margin: 10px 5px;
    width: 100%;
    padding: 10px;
  }
}
