// ==========================================================================
// $FONT-FACE
// ==========================================================================

@if variable-exists(google-fonts-url) {
  @import url($google-fonts-url);
}

@if variable-exists(typefaces) {
  @each $file, $option in $typefaces {
    @font-face {
      font-family: map-get(map-get($typefaces, $file), family);
      font-style: map-get(map-get($typefaces, $file), style);
      font-weight: map-get(map-get($typefaces, $file), weight);
      src: url("../../fonts/#{$file}.eot");
      src: local("#{map-get($typefaces, $file), family}"),
        local("#{map-get($typefaces, $file), local}"),
        url("../../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../../fonts/#{$file}.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../../fonts/#{$file}.woff") format("woff"),
        /* Modern Browsers */ url("../../fonts/#{$file}.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../../fonts/#{$file}.svg#Poppins")
          format("svg"); /* Legacy iOS */
    }
  }
}


/* poppins-100 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/poppins-v6-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Poppins Thin'), local('Poppins-Thin'),
       url('../../fonts/poppins-v6-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/poppins-v6-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
       url('../../fonts/poppins-v6-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/poppins-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light'), local('Poppins-Light'),
       url('../../fonts/poppins-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/poppins-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('../../fonts/poppins-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/poppins-v6-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('../../fonts/poppins-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/poppins-v6-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
       url('../../fonts/poppins-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/poppins-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/poppins-v6-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/poppins-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/poppins-v6-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}