.activation-page {
  height: 100vh;
  width: 100%;
  background: $color-primary;
  background: url("../../img/bg-login-core.jpg") no-repeat center center;
  background-size: cover;
}

.activation-section {
  & > .header {
    text-align: center;
    margin-bottom: 8px;
  }
  & > .content {
    margin-top: 16px;
    .activation-box {
      width: 100%;
      padding: 40px 16px;
      border-radius: 4px;
      background: $white;
    }
  }
}
