.main-header {
  .logo-tagline {
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
  .icon-logo {
    fill: $color-primary;
    height: 40px;
  }
  .dropdown-item {
    &:active,
    &:focus {
      background: transparent;
      color: $color-primary !important;
    }
  }
}

.main-content {
  .complete-profile-info {
    .profile-image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border: 1px solid #eee;
      @include media-breakpoint-down(md) {
        width: 120px !important;
        height: 120px !important;
        object-fit: cover;
      }
    }
    .upload-text {
      font-size: 14px;
      color: #9ea0a5;
    }
  }
  .btn-primary {
    &.unactive {
      cursor: not-allowed;
    }
  }
  .top-nav {
    margin-bottom: -1px;
    border-bottom: 1px solid #e6e6e6;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: inset 0 -2px 1px 0 #d1d1d1;
    @include media-breakpoint-up(sm) {
      padding-left: 17%;
    }
    @media (min-width: 768px) and (max-width: 1023.98px) {
      padding-left: 5%;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
      .top-nav-list {
        padding: 0.5rem !important;
      }
    }
    .list-group-item {
      border: 1px solid #efefef;
    }
    .top-nav-list {
      height: 50px;
      @include media-breakpoint-down(sm) {
        display: block;
        border: none !important;
      }
      &.active {
        background-color: #f1f1f1;
        & > .link-info {
          color: $color-primary;
        }
        & > .icon-menu {
          fill: $color-primary;
        }
      }
      & > .link-info {
        color: #4c6085;
        font-size: 14px;
        &.hover {
          color: $color-primary;
        }
      }
      & > .icon-menu {
        &.hover {
          color: $color-primary;
        }
      }
      &:hover {
        text-decoration: none;
        color: $color-primary;
        & > .link-info {
          color: $color-primary;
        }
        & > .icon-menu {
          fill: $color-primary;
        }
      }
      &:active {
        color: $color-primary;
        & > .link-info {
          color: $color-primary;
        }
        & > .icon-menu {
          fill: $color-primary;
        }
      }
      &.unactive {
        cursor: not-allowed;
        & > .link-info {
          color: #d0ccd0;
        }
        & > .icon-menu {
          fill: #d0ccd0;
        }
      }
    }
  }
}

.nav-icons-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.nav-icons {
  &:hover {
    text-decoration: none;
  }
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  svg, img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
  i {
    font-size: 25px;
    color: #4c6085;
  }
  p {
    margin: 0 !important;
    color: #000;
    font-size: 10px;
    font-weight: 300;
    margin-top: 5px;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
}

.whitey .nav-icons {
  color: white !important;
  p,
  i {
    color: white;
  }
}

.links-wrapper {
  width: 300px;
  .nav-icons {
    margin: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
    svg, img {
      width: 25px;
      height: 25px;
      margin-bottom: 5px;
    }
    i {
      width: 20%;
      margin-right: 20px;
    }
    p {
      width: 80%;
      color: gray;
      font-weight: lighter;
    }
  }
}

.profile-wrapper {
  margin-top: 50px;
  width: 100%;
  .side-profile-img {
    width: 100px;
    margin: 0 auto;
    display: flex;
  }
}

.side-logout {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  i {
    color: $primary;
  }
}

.main-profile-image {
  position: relative;
  a {
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      @include media-breakpoint-down(md) {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.img-small-nav {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.unlink {
  color: unset;
  &:hover {
    text-decoration: none;
    color: grey;
  }
}

.editor-icon {
  position: absolute;
  bottom: 0;
  right: 12px;
  background: #4c6085;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: white;
    font-size: 10px;
  }
}

.icn-wrap {
  width: 35px;
  svg {
    width: 20px;
  }
}

.out-collapse {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
