.search-ovr {
  width: 100%;
  margin: 20px auto 50px;
  &--landing {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 20px;
  }
}

.search-ovr-btn {
  font-weight: unset !important;
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
  }
}

.search-form-banner {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    width: 80px;
    margin: 0 auto;
  }
}

.s-back-button {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.s-result {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.s-result-ovr-item-title {
  color: $primary;
  font-weight: bold;
}

.s-result-ovr-item-body {
  color: black;
  font-size: 25px;
  font-weight: bold;
}

.s-result-ovr-item {
  margin-bottom: 15px;
}

.outside-nav-inner {
  padding: 0 10px;

  @include media-breakpoint-down(lg) {
   width: 100%;
   justify-content: end;
  }
}

// .custom-nav-wrapper {
//   @include media-breakpoint-down(md) {
//     box-shadow: 1px -1px 9px 0px $color-primary;
//   }
// }

.custom-nav {
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  @include media-breakpoint-down(lg) {
    justify-content: end;
  }
}
