.services-section {
  margin: 40px 0 40px;
  .section-title {
    font-weight: 600;
    font-size: 50px;
    color: $color-secondary;
  }
  .services-list {
    .service-title {
      color: $color-secondary;
      font-weight: 500;
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin: 1rem;
      }
    }
    .service-info {
      font-size: 14px;
      color: #8d9299;
    }
  }

  .more-services {
    @include media-breakpoint-down(sm) {
      float: left !important;
    }
  }
}

.services__icon {
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.services__status {
  position: absolute;
  top: 0;
  left: 5;
}
