.generic-listing {
    margin: 0 15px;
    .generic-list-item {
      margin-top: 5px;
      -webkit-box-shadow: 0px 3px 6px #00000029;
      -moz-box-shadow: 0px 3px 6px #00000029;
      box-shadow: 0px 3px 6px #00000029;
      cursor: pointer;
      border-top: 2px solid $lighter-blue;
      border-radius: 10px;
      min-height: 70px;
      &.active {
        background: #f2f7fe;
        border: 1px solid #5272aa;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 10px;
          height: 63px;
          right: -10px;
          top: 3px;
          background: url('../../img/polygon.png');
        }
      }
    }
    .generic-list-item-mobile {
      margin-top: 5px;
      -webkit-box-shadow: 0px 3px 6px #00000029;
      -moz-box-shadow: 0px 3px 6px #00000029;
      box-shadow: 0px 3px 6px #00000029;
      cursor: pointer;
      border-top: 2px solid $lighter-blue;
      border-radius: 10px;
      min-height: 70px;
      &.active-mobile {
        background: #f2f7fe;
        border: 1px solid #5272aa;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 10px;
          height: 63px;
          right: -10px;
          top: 3px;
        }
      }
    }
  }

  .list-name {
    font-size: 23px;
    font-weight: bold;
    margin: 30px 20px 5px;
  }

  .module-inner {
    min-height: 600px;
    background: #fff;
    margin: 20px 15px;
    padding: 20px 30px;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    -moz-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
  }

  .module-inner-title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .generic-module-nav {
    display: flex;
    .nav-link {
      color: $color-primary;
      background: $white;
      margin-right: 10px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 20px;
      border-radius: 5px;
      &.active {
        background: $color-primary;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  