// Color Palette
// examples based on the official Sass styleguide

// descriptive colors
// =============
$alizarin-crimson: #ec1b23;
$black: #000000;
$totem-pole: #393f44;
$apple-green: #9bc03c;
// $apple-green: #fb002c;
$dark-blue: #4c6085;
$light-blue: #5da5e0;
$lighter-blue: #F2F7FF;
$title: #323943;
$darker-blue: #5272AA;
$dark-black: #454545;
$light-gray: #9FA2A7;
$dark-gray: #65676B;
$sepia: #5170A7;
$alice-blue: #f1f3fc;

$wedgewood: #4c6085;
$footer: #323843;
$black: #323843;
$shark: #292e34;
$outer-space: #393f44;
$abbey: #4d5258;
$rolling-stone: #72767b;
$oslo-gray: #8b8d8f;
$silver: #bbbbbb;
$alto: #d1d1d1;
$gallery: #ededed;
$wild-sand: #f5f5f5;
$alabaster: #fafafa;
$white: #ffffff;

$green-100: #3a835b;
$red-100: #d9364c;

// Shades of Gray
// =============
$gray-050: $alabaster;
$gray-100: $wild-sand;
$gray-200: $gallery;
$gray-300: $alto;
$gray-400: $silver;
$gray-500: $oslo-gray;
$gray-600: $rolling-stone;
$gray-700: $abbey;
$gray-800: $outer-space;
$gray-900: $shark;

$blue-accent: #f2f7fe;

// main color palette
// =============
$color-primary: $wedgewood;
$color-secondary: $light-blue;
$color-accent: $black;
$color-shadow: rgba($gray-600, 0.1);
$color-note: $gray-800;

// Common colors
$color-background: $white;
$color-background-shade: $gray-050;
$color-background-invert: $gray-800;
$color-text: darken($black, 15);
$color-text-weak: $gray-200;
$color-text-strong: $alizarin-crimson;
$color-text-heading: $gray-200;
$color-text-invert: rgba($color-background, 0.75);
$color-text-strong-invert: $color-background;

// Links
$color-link: $color-primary;
$color-link-hover: darken($color-primary, 10%);
$color-link-visited: lighten($color-primary, 5%);

// Code
$color-code: $shark;
$color-code-background: $gray-050;
$color-pre: $outer-space;
$color-pre-background: $gray-100;

// Selections
$color-selection: #b3d4fc;

// Messages
$color-message: #f4ecbb;
$color-success: #2cde2c;
$color-warning: #cf8600;
$color-important: #d00;
$color-notice: #66b;
