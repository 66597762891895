.clear-modal {
  overflow: hidden;
  color: $dark-blue;
  padding: 45px 35px;

  @include media-breakpoint-down(sm) {
    padding: 45px 5px;
  }

  .modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
    font-size: 2.7em;
    line-height: 0.5em;
    font-weight: 400;
  }
  .transaction-title {
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }
  }
  .back-button {
    position: absolute;
    top: -38px;
    left: -27px;
    color: $dark-blue;
    font-size: 20px;
    @include media-breakpoint-down(sm) {
      top: -38px;
      left: 8px;
    }
    .text {
      margin-left: 8px;
      font-size: 14px;
      vertical-align: middle;
      font-weight: 500;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 25px;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .close {
    outline: none;
    opacity: 1;
  }
}

.reset-overflow {
  overflow: unset !important;
}

.modal-custom-div{
  margin: 1.5rem !important;
}
