.about-banner {
  background: $color-primary;
  
  background-size: cover;
  height: 286px;
  color: white;
  top: 80px;
  position: relative;

  .about-title {
    font-weight: 600;
    font-size: 48px;
  }
}

.about-wrapper {
  // margin-top: 100px;
  .about-content-title {
    font-size: 24px;
    color: $color-secondary;
  }
  .about-content-body {
    font-size: 14px;
    color: #8d9299;
  }
}


.about-wrapper-public {
  margin-top: 100px;
  .about-content-title {
    font-size: 24px;
    color: $color-secondary;
  }
  .about-content-body {
    font-size: 14px;
    color: #8d9299;
  }
}

.how-to-bg {
  background: #4c6085;
  
  background-size: cover;
  height: 286px;
  color: white;
  position: relative;
  padding: 70px;
  .how-to-title {
    font-weight: 600;
    font-size: 48px;
    text-align: center;
  }
  .how-to-value {
    font-size: 20px;
    text-align: center;
  }
}

.how-to-title-below {
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.how-to-value-below {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #9fa2a7;
}
