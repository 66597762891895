.inner-addon {
  position: relative;
}

/* style glyph */
.inner-addon .icon {
  position: absolute;
  pointer-events: none;
  width: 16px;
  height: 16px;
  margin: 12px 4px 12px 10px;
  z-index: 999;
}

.inner-addon .btn {
  position: absolute;
  width: 48px;
  height: 100%;
  margin: 0;
  z-index: 999;
  outline: 0;
  padding: 8px;
  transition: 500ms ease all;
  &:focus,
  &:hover {
    outline: 0;
    box-shadow: none;
    transition: 500ms ease all;
  }
}

.inner-addon > .form-control {
  transition: 500ms ease all;
}

/* align glyph */
.left-addon .icon {
  left: 0;
  top: 0;
}

.right-addon-button .icon-right {
  top: 0;
  right: 0;
}

.right-addon-button .btn-right {
  top: 0;
  right: 0;
}

/* add padding  */
.left-addon .form-control {
  padding-left: 48px;
}
.right-addon .form-control {
  padding-right: 48px;
}
