.success-page {
  font-size: 12px !important;
  color: #4c6085;
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  p {
    line-height: 22px;
  }
  .gray-container {
    background: $gray-100;
    width: 100%;
    border-radius: 5px;
  }
}