.e-rental-container {
  box-shadow: 0px 0px 11px 0px rgba(76, 96, 133, 0.21);
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 5px;

  >.tab {
    >li.active {
      >hr {
        background: #bad3f9;
        height: 2px;
      }
    }
  }

  

}

.e-rental-item {

  box-shadow: 0px 3px 6px #00000014;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 5px;
    
  >.photo {
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: 319px;
    }

    >img {
      width: 319px;
      height: 196px;
      max-height: 196px;
    }
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}