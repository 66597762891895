.preview-upload-image-container {
  position: relative;
  width: 100%;
  .image-holder {
    display: flex;
    border: 1px solid #d9d9d9;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 100px;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
  }
  // overflow-y: scroll;
  .button-delete {
    position: absolute;
    font-size: 20px;
    z-index: 1;
    top: -10px;
    right: -10px;
    padding: 0;
    margin: 0;
  }
}

.qr-image {
  width: 100px;
  height: auto;
}

