// @source http://smacss.com/book/type-layout
//
// Layout Rules
//
// Layout styles can also be divided into major and minor styles based on
// reuse. Major layout styles such as header and footer are traditionally
// styled using ID selectors but take the time to think about the elements
// that are common across all components of the page and use class selectors
// where appropriate.

.w-sm-100 {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.py-sm-2 {
  @include media-breakpoint-down(sm) {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}