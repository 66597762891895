.chat-room {
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
  height: 100%;
  width: 100vw;
  z-index: 1000;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);

  @include media-breakpoint-up(md) {
    width: 400px;
    height: 500px;
    bottom: 0;
    right: 10px;
  }

  @include media-breakpoint-down(sm) {
    height: 650px;
    width: 350px;
    padding: 0px;
  }
}

.item-left {
  background-color: #f2f7ff;
  border-radius: 5px;
  margin: 1px auto;
  max-width: 425px;
  padding: 10px;
  width: -webkit-fit-content;
  float: left;
  color: black;
  clear: both;
  font-size: 12px;
  border: none;
}

.item-right {
  background-color: $color-primary;
  border-radius: 5px;
  margin: 1px auto;
  max-width: 425px;
  padding: 10px;
  width: -webkit-fit-content;
  float: right;
  color: white;
  clear: both;
  font-size: 12px;
  word-wrap: break-word;
  border: none;
}

.bubble {
  overflow: auto;
}

.bubble > .name-left {
  float: left;
  padding: 0;
  margin: 0;
}

.bubble > .name-right {
  float: right;
  padding: 0;
  margin: 0;
}

.chat-input {
  border: none;
  margin: 0;
  resize: none;
}

.chat-header {
  background: #f2f7ff 0% 0% no-repeat padding-box;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.chat-history {
  padding-left: 5px;
  padding-right: 5px;
  height: calc(100vh - 100px);

  @include media-breakpoint-up(md) {
    height: 405px;
  }
}

.chat-form {
  border-top: 2px solid #d7d7d7;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.chat-feed-container {
  height: calc(100% - 100px);

  @include media-breakpoint-up(md) {
    height: 405px;
  }
}

body {
  &.has-chat {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
  }
}
