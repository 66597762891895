.hiring-profile {
  padding: 10px;
  font-size: 12px;
}

.hiring-profile-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.hiring-image {
  margin-right: 10px;
  img {
    width: 120px;
    border-radius: 60px;
  }
}

.hiring-wrapper-content {
  display: flex;
  align-items: center;
}
.hiring-contact {
  display: flex;
  div {
    margin-right: 20px;
    color: #65676b;
    &:after {
      content: "|";
      @include media-breakpoint-down(sm) {
        content: "";
      }
    }
  }
  i {
    margin-right: 8px;
    color: #5da5e0;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.hiring-name {
  font-size: 14px;
  font-weight: bold;
}

.hiring-position {
  color: #9fa2a7;
  margin-bottom: 12px;
}

.hiring-profile-edit {
  button {
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      width: 100%;
    }
  }
}

.hiring-search {
  padding: 10px;
  margin-left: 20px;
  input {
    z-index: -1;
  }
}

.hiring-item {
  color: #323943;
  @include border-blur;
  padding: 30px 20px;
  border-radius: 15px;
  margin: 10px;
  width: 30%;
  @include media-breakpoint-down(sm) {
    width: 45%;
    margin: 5px;
  }
  &-image img {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
  &-contents {
    font-size: 12px;
  }
  &-position {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
  &-location {
    color: #9fa2a7;
    margin-bottom: 15px;
  }
  &-salary {
    font-size: 14px;
    font-weight: bold;
  }
}

.hiring-more {
  margin-top: 20px;
  display: flex;
  width: 100%;
  text-align: center;
  button {
    margin: 0 auto;
  }
}
