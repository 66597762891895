@viewport {
  width: device-width;
}

html {
  background-color: $color-background;
  box-sizing: border-box;
  height: 100%;
}

* {
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

// @media (min-width: 768px) and (max-width: 1079.98px) {
//   * {
//     font-size: 12px;
//   }
// }

body {
  -webkit-font-smoothing: antialiased;
  color: $color-text;
  font-family: $font-family-default;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

.table thead th {
  z-index: 1 !important;
}

a {
  background: transparent;
  color: $color-link;
  text-decoration: none;
  transition: color 0.2s;
  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
  }
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-display;
  font-weight: normal;
}

h3.text-step {
  font-weight: bolder;
  color: #5271aa;
}

ol.nostyle {
  list-style-type: none;
}

li.nostyle {
  list-style-type: none;
}

.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}

.iframe-inner {
  height: 100vh;
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

.regenerate-inner {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 30%;
  margin-top: -150px;
}

.regenerate-inner-title {
  text-align: center;
  color: $dark-blue;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.regenerate-icon {
  text-align: center;
  font-size: 50px;
  color: $success;
}

.regenerate-sub {
  text-align: center;
}

.thistory {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.justify-content-sm-center {
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.btn-sm-block {
  margin: 0 3px;
  @include media-breakpoint-down(sm) {
    margin: 10px 0;
    width: 100%;
    display: block;
  }
}

.mt-sm-2 {
  @include media-breakpoint-down(sm) {
    margin-top: 5px;
  }
}

.about-banner-private {
  top: -15px !important;
}
