.image-preview{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 99999;
}
.image-preview-close{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
}
.image-preview-image{
    height: 100%;
    width: 100%;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: contain !important;
}

.react-datepicker-popper{
    width: 314px;
    z-index: 5;
}