.header-section {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: background-color 0.3s ease-in-out;
  background: #fff;
  border-bottom: 5px solid #4c6085;
  min-height: 81px;
  max-height: 81px;

  .navbar {
    transition: background-color 0.3s ease-in-out;
  }

  @include media-breakpoint-down(sm) {
    background-color: #fff;
    transition: all 0.2s ease-in-out;
  }
  @include media-breakpoint-down(md) {
    background-color: #fff;
    transition: all 0.2s ease-in-out;
  }

  .logo-cont-sm {
    @include media-breakpoint-down(sm) {
      margin-left: -5px !important;
      // margin-left: 50%;
      // transform: translateX(-50%);
    }
  }

  .logo-cont-wrapper-public {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    max-height: 80px;
    

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .logo-cont-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-height: 80px;
  }

  .navbar-toggler-icon {
    @include media-breakpoint-down(sm) {
      z-index: 2;
      // color: $color-accent !important;
      // position: absolute;
      // top: 20px;
      // left: 0;
    }
  }
  .nav-item {
    & .nav-link {
      font-size: 14px;
      color: #656b72;
      &:hover {
        font-weight: bold;
      }
    }
  }
  .nav-item.active {
    & .nav-link {
      color: $color-primary;
      &:hover {
        font-weight: bold;
      }
    }
  }
  .icon-logo {
    max-height: 60px;
    fill: $color-primary;
    @include media-breakpoint-down(sm) {
      // width: 260px;
      // margin-bottom: 5px;
    }
  }
  .navbar-collapse {
    .show {
      transition: all 0.2s ease-in-out;
    }
  }
}

.bg-dark-blue {
  background: #4c6085;
}

.white-links .nav-item .nav-link {
  color: white !important;
}

// .navbar {
//   @include media-breakpoint-down(sm) {
//     padding: 10px 0 0 0 !important;
//   }
// }
