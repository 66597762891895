.status-container {
  label {
    padding: 5px 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    margin: 0px !important;
  }
  .status-pending {
    background-color: $gray-300;
    color: $gray-500;
  }
  .status-processing {
    color: $white;
    background: $black;
  }
  .status-for-appearance {
    color: $white;
    background: $dark-blue;
  }
  .status-paid {
    color: $white;
    background: $apple-green;
  }
  .status-reject {
    color: $white;
    background: $red-100;
  }
}

.status-pending{
  background: #A3B3F9;
}

.status-received{
  background: #8AA4F8;
}

.status-checking{
  background: #B1CDF8;
}

.status-delivery{
  background: #D9F5FA;
}

.status-printing{
  background: #8EEED7;
}

.status-appearance{
  background: #AFF2F3;
}

.status-appointment{
  background: #7790CB;
}

.status-waiting{
  background: #5272AA;
}

.status-paid{
  background: #4C6085;
}

.status-pickup{
  background: #5DA5E0;
}

.status-lab{
  background: #85B4D2;
}

.status-printed{
  background: #4C4372;
}

.status-danger{
  background: #4F4F50;
}
