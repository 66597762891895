.ecom-cat {
  @include border-blur;
  min-height: 500px;
  border-radius: 5px;
  padding: 10px;
  &__inner {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
  .react-tabs__tab-list {
    display: flex;
    padding: 0 !important;
    color: #9fa2a7;

    // @include scrollbars(5px, #4c6085, #93a2b4);
    @include media-breakpoint-down(md) {
      display: block;
      margin: 0 !important;
      padding: 0 0 15px 0 !important;
      border-bottom: 1px solid grey;
    }

    li {
      padding: 10px;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      list-style-type: none;
      margin: 0 20px;
      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }
  }
  .react-tabs__tab--selected {
    border-bottom: 3px solid #bbc8e5;
    color: #000;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      border-bottom: unset;
      background: #bbc8e5;
      padding: 5px;
      color: white;
      border-radius: 10px;
    }
  }
  &__inner {
    padding-top: 20px;
  }
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .product {
    @include border-blur;
    overflow: hidden;
    margin: 10px;
    border-radius: 15px;
    width: 185px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .product-image {
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 200px;
      }
    }
  }
  .product-contents {
    padding: 5px 10px 15px;
    font-size: 12px;
    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
    .product-title {
      margin-bottom: 20px;
      height: 25px;
      word-wrap: break-word;
    }
    .product-rating i {
      color: yellow;
    }
    .product-tags i {
      color: grey;
    }
  }
}

.tab-ecom-panel-container {
  width: 100%;
  margin: 0 auto;
}

.placeholder-product {
  margin: 0 auto;
  color: #000;
  text-align: center;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  font-size: 20px;
  font-weight: bold;
}

.ad-panel {
  margin-top: 30px;
  background: #efefef;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-size: 11px;
}
