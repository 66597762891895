// Overrides
//
// [These styles override] all other layers, and has the power
// to override anything at all that has gone before it. It is
// inelegant and heavy-handed, and contains utility and helper
// classes, and hacks.
//
// @source - http://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528

.table {
  thead th {
    z-index: 0;
  }
}

.border-md-bottom {
  @include media-breakpoint-down(md) {
    border-bottom:1px solid #d1d1d1 !important;
  }
}

.converter-handle {
  margin-top: -4.5px !important;
  background-color: white;
}

.gcash-button {
  background-color: #007CFA !important;
}

.gcash-button:disabled {
  background-color: #bdb9bd !important;
  border-color: #bdb9bd !important;
}


@media (min-width: 768px) and (max-width: 1079.98px) { 
  .private-left-pane {
    padding-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


@media (max-width: 500px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem !important;
    line-height: 2rem !important;
}
}

.player-wrapper {
  // height: 650px !important;
  // max-height: 650px !important;
}
