.login-container {
  position: relative;
  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999;
    font-size: 1.8em;
  }
  .form-section {
    padding: 70px;
    @include media-breakpoint-down(sm) {
      padding: 40px;
    }

    .form-title,
    .form-sub-title {
      color: $color-secondary;
    }
    .form-sub-title {
      font-size: 14px;
    }
    .icon-logo {
      fill: $color-primary;
      max-height: 80px;
      width: auto;
    }
    .logo-label {
      font-weight: 14px;
      color: $color-secondary;
    }
    .forgot-password {
      font-weight: 400;
      font-size: 12px;
      color: $color-secondary;
      &:hover {
        text-decoration: none;
      }
    }
    .sign-up {
      font-size: 12px;
      color: $color-secondary;
      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
  .info-section {
    position: relative;
    background-size: cover;
    padding: 70px;
    @include media-breakpoint-down(sm) {
      display: none;
      background-color: transparent;
    }

    .info-title {
      font-size: 28px;
    }
    .info-service-title {
      font-weight: 600;
      color: white;
    }
    .info-service-desc {
      font-size: 12px;
      color: white;
    }
  }
}

.back-login {
  background: #f2f7ff;
  border-radius: 50%;
  color: #4c6085;
  margin-top: 10px;
  margin-left: 15px;
  padding: 5px;
}

.login-options {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  span {
    button {
      font-size: 10px;
      padding: 0 !important;
      &:active {
        box-shadow: none;
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
  }
}
.iagree-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
}

.iagree {
  font-size: 1rem;
  margin: 0 !important;
  padding: 0 2px !important;
  @include media-breakpoint-down(sm) {
    font-size: 9.5px;
  }
  &:hover {
    text-decoration: none !important;
    transform: unset;
  }
}

.iagree-span {
  @include media-breakpoint-down(sm) {
    font-size: 9.5px;
    margin-left: 5px;
  }
}

.iagree-check {
  margin-right: 15px;
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.form-verification {
  input:before {
    content: "";
    display: inline-block;
    width: 5px;
  }
  padding-left: 15px;
  letter-spacing: 35px;
  border: 0;
  background-image: linear-gradient(
    to left,
    $color-secondary 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 42px 2px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 250px;
}
.otp-value {
  outline: none;
  border: none;
  display: block;
  line-height: 0.9em;
  font-size: 10pt;
  width: 100%;
  padding: 10px;
  color: $color-secondary;
  font-weight: 600;
  width: 100%;
}

//otp
#partitioned {
  padding-left: 19px;
  margin-right: -42px;
  letter-spacing: 33px;
  border: 0;
  background-image: linear-gradient(
    to left,
    $color-secondary 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 40px 1px;
  background-repeat: repeat-x;
  background-position-x: -2px;
  width: 280px;
  min-width: 250px;
  &:active {
    box-shadow: none;
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  width: 250px;
  overflow: hidden;
}

.forgot-container {
  position: relative;
  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999;
    font-size: 1.8em;
  }
  .form-section {
    padding: 70px;
    @include media-breakpoint-down(sm) {
      padding: 40px;
    }

    .form-title,
    .form-sub-title {
      color: $color-secondary;
    }
    .form-sub-title {
      font-size: 14px;
    }
    .icon-logo {
      fill: $color-primary;
      max-height: 80px;
      width: auto;
    }
    .logo-label {
      font-weight: 14px;
      color: $color-secondary;
    }
    .forgot-password {
      font-weight: 400;
      font-size: 12px;
      color: $color-secondary;
      &:hover {
        text-decoration: none;
      }
    }
    .sign-up {
      font-size: 12px;
      color: $color-secondary;
      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
  .info-section {
    margin-top: -52px;
    position: relative;
    background-size: cover;
    padding: 70px;
    @include media-breakpoint-down(sm) {
      display: none;
      background-color: transparent;
    }

    .info-title {
      font-size: 28px;
    }
    .info-service-title {
      font-weight: 600;
      color: white;
    }
    .info-service-desc {
      font-size: 12px;
      color: white;
    }
  }
}

.login-div-buttons {
  display: flex;
  height: 30px;
  width: 100%;

  .login-div-item {
    border: 1px solid #F1F3FC ;
    margin-right: 5px;
    border-radius: 10px;
    .login-div-span {
      color: #4c6085;
      font: normal normal normal 12px Poppins;
    }
    background: #F1F3FC 0% 0% no-repeat padding-box;
  }
  .active {
    .login-div-span {
      color: #ffffff;
      font: normal normal normal 12px Poppins;
    }
    background: #5272aa 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
