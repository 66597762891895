.e-wallet {
  font-family: "Poppins";
  padding-bottom: 50px;
  .card-e-wallet {
    box-shadow: 2px 2px 6px #00000014;
    border-radius: 10px;

    @include media-breakpoint-down(sm) {
      box-shadow: none;
    }

    .list {
      padding-bottom: 20px;

      @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        overflow: auto;

      }


      .e-wallet-title {
        font-size: 14px;
        font-weight: 500;
        color: #9fa2a7;
      }
    }
    .e-wallet-amount {
      font-size: 22px;
      font-weight: 600;
      color: #4c6085;
    }
  }

  .e-wallet-list {
    &__inner {
      padding: 15px;
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        padding: 5px;
      }
    }
  }

  .e-wallet-item {
    font-size: 17px;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: max-content;
    }
    & img {
      margin: 0px 14px 5px 0px;
      width: 20px;
      height: 20px;
    }
    & > a {
      flex: 5;
      color: #323943;
      &:hover {
        color: #5da5e0;
        text-decoration: none;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

@media (min-width: 576px) {
  .col-sm-3.card-e-wallet {
    flex: 0 0 20%;
  }
}

.form-section.send_fund {
  margin-left: 25px !important;
  margin-top: 25px !important;

  @include media-breakpoint-down(sm) {
    margin-left: 0 !important;
    margin-top: 0 !important;
    padding: 0;
  }

  
}

.send-fund-buttons {
  .button-cancel {
    width: auto !important;
  }
}

.send-fund-buttons {


  &.with-back {
    width: 75%;
    @include media-breakpoint-down(sm) {
      width: unset;
    }
    .button-cancel {
      margin-left: 40px !important;
      @include media-breakpoint-down(sm) {
        margin-left: 0 !important;
      }
    }
  }
}

.title {
  font: normal normal 600 20px/35px Poppins;
  color: #323943;
}

.full-label {
  font-weight: 600 !important;
  &.send_fund {
    color: #9fa2a7 !important;
  }
  &.review {
    color: #4c6085 !important;
  }
  &.load_mobile {
    color: #4c6085 !important;
  }
}

.normal-label {
  font-weight: 400 !important;
}

.form-control {
  &.send_fund {
    border: 1.5px solid #d3d3d3 !important;
    border-radius: 5px !important;
    height: 45px !important;
    width: 88%;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}

.form-input-group.send_fund {
  p {
    font: normal normal 500 14px Poppins;
    color: #000000;
  }
  & div.form-group {
    margin-bottom: 6px !important;
    width: 88% !important;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
  & > div.css-2b097c-container {
    width: 88% !important;
    height: 45px !important;
    & > div.css-yk16xz-control {
      min-height: 45px !important;
      & > div > div.css-1wa3eu0-placeholder {
        color: #dfdfdf;
        font-family: "Poppins";
        font-size: 14px;
      }
    }
    & > div.css-1pahdxg-control {
      min-height: 45px !important;
    }
  }
}

.btn {
  &.send-fund {
    border-radius: 5px;
    width: 50%;
    margin-left: 40px !important;

    @include media-breakpoint-down(sm) {
      width: 100% !important;
      margin-left: 0 !important;
    }

    &.with-back {
      width: 50% !important;
      margin-left: 0 !important;
    }
  }
}

.cash-in-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.fund {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.fund > div.box {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  margin: 0 16px 10px 0;
  &:hover {
    box-shadow: 0 0 10px #5da5e0;
  }
  @include media-breakpoint-down(sm) {
    flex-basis: 30%;
    margin: 0 10px 10px 0;
  }
}

.fund > div.box > div {
  height: 95px;
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    height: 50px;
  }
  & button {
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #d9dbe0;
    border-radius: 5px;
    width: 100%;
    color: #5da5e0;
    @include media-breakpoint-down(sm) {
      font-size: 12px !important;
    }
  }
}

.other_amount {
  margin-right: 10px;
  // padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.other_amount > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  & input {
    border: 2px solid #d9dbe0;
    height: 40px;
    width: 65%;
    margin-bottom: 10px;
    padding-left: 15px;
    font-weight: 600;
    font-size: 12px;
    &:focus {
      outline: none !important;
    }
    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }
  }
  & button {
    width: 35%;
    border-radius: 0px;
    height: 40px;
    background: #4c6085 0% 0% no-repeat padding-box;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 14px;
    color: #ffffff;
    & img {
      margin: 0px 10px 5px 0px;
    }
    @include media-breakpoint-down(sm) {
      width: 47% !important;
    }
  }
}

.cashInImg {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.cashInText {
  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

.load_mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  & div.amount {
    flex: 30.5%;
    height: 40px;
    margin: 0 10px 10px 0;
    &:hover {
      box-shadow: 0 0 10px #5da5e0;
    }
    & button {
      font-size: 14px;
      font-weight: 600;
      border: 1px solid #5da5e0;
      border-radius: 5px;
      width: 100%;
      color: #5da5e0;
    }
  }
}

.load_option {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  & div {
    flex: 20;
    height: 40px;
    margin: 0 5px 10px 0;
    &:hover {
      box-shadow: 0 0 10px #5da5e0;
    }
    & button {
      font-size: 14px;
      font-weight: 400;
      border-radius: 5px;
      width: 100%;
      background: #f1f3fc 0% 0% no-repeat padding-box;
      color: #7992cc;
    }
  }
}

.telco {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.telco img {
  margin: 0px 15px 5px 0px;
}

h1.title.load_mobile_number {
  font: normal normal 500 18px/35px Poppins;
}

.load_btn_active {
  background: #4c6085 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.load_description {
  font-size: 12px !important;
}

.e-wallet-cash-out {
  margin: 35px 0px 40px 0px;
}

.e-wallet-receive-fund {
  margin: 35px 0px 40px 0px;
}

.load_combo_list {
  margin-bottom: 20px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.load_combo_card:last-child {
  margin-bottom: 2px;
}

.load_combo_card {
  display: flex;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 6px #00000029;
  align-items: center;
  margin-bottom: 10px;
  & > div {
    padding-top: 10px;
    &.load_combo_amount {
      text-align: center;
      width: 100px;
      & span {
        font: normal normal bold 20px/30px Poppins;
        color: #4c6085;
      }
    }
    &.load_combo_description {
      flex: 1;
      & strong {
        font: normal normal bold 16px/30px Poppins;
        color: #4c6085;
      }
      & p {
        font: normal normal normal 11px/20px Poppins;
        color: #9fa2a7;
      }
    }
    &.load_combo_check_box {
      width: 70px;
    }
  }
}

$md-radio-checked-color: #5da5e0;
$md-radio-border-color: #5da5e0;
$md-radio-size: 30px;
$md-radio-checked-size: 20px;
$md-radio-ripple-size: 10px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.radio_button {
  margin: 16px 0;
  margin-left: 20%;
  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    color: transparent;
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 5px;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 1px solid $md-radio-border-color;
    }

    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

.e_wallet_btn {
  padding-left: 0px;
}

.requests_list {
  margin-bottom: 5px;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.requests_card {
  display: flex;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 12px #03030314;
  margin-bottom: 3px;

  & > div {
    padding-top: 10px;
    s &.requests_card_description {
      flex: 1;
      & span {
        font: normal normal normal 16px/20px Poppins;
        color: #65676b;
      }
      & strong {
        font: normal normal 600 16px/20px Poppins;
        color: #65676b;
      }
      & p {
        font: normal normal normal 11px/20px Poppins;
        color: #9fa2a7;
      }
    }
    &.load_combo_check_box {
      width: 70px;
    }
  }
}

.requests_amount {
  font: normal normal 600 11px/20px Poppins;
  color: #9fa2a7;
  padding-bottom: 20px !important;
}

.requests_card-amount {
  font: normal normal 600 20px/20px Poppins;
  color: #65676b;
}

.request_info {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.pay_request_label {
  height: 55px;
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #323943;
  opacity: 1;
}

.requested_amount {
  text-align: center;
  font: normal normal 600 16px/20px Poppins;
  letter-spacing: 0px;
  color: #65676b;
  opacity: 1;
}

.pay_to_label {
  text-align: left;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #323943;
  opacity: 1;
}

.receive_from {
  left: 1387px;
  height: 27px;
  text-align: right;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #65676b;
  opacity: 1;
}

.available_balance_label {
  text-align: left;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #323943;
  opacity: 1;
}

.remaining_balance {
  height: 200px;
  text-align: right;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #65676b;
  opacity: 1;
}

.cancel_button {
  width: 253px;
  border: 2px solid #4c6085;
  border-radius: 5px;
  opacity: 1;
}

.accept_button {
  width: 253px;
  background: #4c6085 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}
