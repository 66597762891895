footer {
  // background-color: $color-accent;
  background-color: #fff !important;
  color: #5d6472;
  margin-top: 20px;
  position: relative;
  @include media-breakpoint-between(lg, xl) {
    margin-top: 50px;
  }
  & .icon-logo {
    fill: white;
    margin-bottom: 10px;
    height: 50px;
  }
  .footer-info {
    color: #5d6472;
    font-size: 12px;
  }
  .navbar-footer {
    li > a {
      color: #5d6472;
      font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    .list-inline-item {
      @include media-breakpoint-down(sm) {
        padding: 0 5px !important;
        margin-top: 10px;
      }
    }
  }
  .social-links {
    font-size: 28px;
    color: #5d6472;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .sub-footer {
    color: #5d6472;
    font-size: 12px;
  }
  .copyright,
  .powered-by {
    font-size: 12px;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .copyright {
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }
  }
  .logo-container {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

.msys-logo {
  width: 150px;
}

.copyright {
  text-align: right;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.powered-cont {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  span {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  a {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}
