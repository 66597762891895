.text-money {
  color: $gray-500;
  font-weight: 700;
  &.positive {
    color: $green-100;
  }
  &.negative {
    color: $red-100;
  }
}

.text-color {
  color: $color-primary;
}

.text-primary {
  color: $color-primary !important;
}

.text-label {
  font-size: 13px;
}

.text-label-link {
  font-size: 13px;
  &:hover {
    text-decoration: none;
    color: $color-secondary;
  }
}

.text-default {
  color: $color-primary;
}

.label-sm {
  font-size: 11px;
}

.text-black {
  color: black;
}
