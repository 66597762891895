.ecom {
  display: flex;
  margin-top: 40px;
  @include media-breakpoint-down(md) {
    display: block;
    margin-top: unset;
  }
  &__soon {
    z-index: 5;
    background: #bad3f9;
    text-align: center;
    color: #fff;
    font-weight: bold;
    position: fixed;
    width: 100%;
    p {
      margin: 0;
    }
    @include media-breakpoint-down(md) {
      position: unset;
    }
  }
  &__title {
    color: $title;
    font-weight: bold;
    font-size: 24px;
  }
  &__search {
    margin: 20px 0;
  }
  &__side {
    position: fixed;
    margin-right: 20px;
    width: 300px;
    min-height: 100vh;
    padding: 20px;
    @include border-blur;
    @include media-breakpoint-down(md) {
      width: 100%;
      position: unset;
      min-height: unset;
      margin-bottom: 20px;
    }
  }
  &__main {
    margin-left: 310px;
    width: 100%;
    // margin-top: 20px;
    @include media-breakpoint-down(md) {
      width: 100%;
      position: unset;
      margin-left: unset;
    }
    &--nav {
      @include border-blur;
      padding: 20px;
      margin: 0 auto;
      margin-bottom: 20px;
      border-radius: 5px;
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
    &--nav-wrapper {
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(md) {
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
    &--nav-item {
      margin: 0 25px;
      text-align: center;
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
    &--body {
      width: 100%;
    }
  }
}

.ecom-item-logo {
  background: $blue-accent;
  padding: 20px;
  border-radius: 50%;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.ecom-item-link {
  color: #9fa2a7;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.logo-active {
  color: $title;
}

.ecom-item-logo {
  width: 80px;
  height: 80px;
}

.logo-active > .ecom-item-logo {
  border: 2px solid #bad3f9;
}
