.wallet-widget {
  -webkit-box-shadow: 2px 2px 6px #00000014;
  -moz-box-shadow: 2px 2px 6px #00000014;
  box-shadow: 2px 2px 6px #00000014;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  &__inner {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    &--right {
      align-items: center;
      flex-direction: column;
      justify-content: center;

      display: flex;
      img {
        margin-right: 5px;
      }
      button {
        margin-top: 5px;
        padding: 0 !important;
        text-decoration: none;
        font-size: 12px;
        color: #323943;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &:hover {
          text-decoration: none;
        }
        &:focus {
          outline: none !important;
          border: 0 !important;
          box-shadow: unset !important;
          text-decoration: none;
        }
      }
    }
  }
}

.wallet-title {
  font-size: 14px;
  color: #72767b;
}

.wallet-amount {
  font-size: 18px;
  font-weight: bold;
  color: #323943;
}

.top-up {
  width: 100%;
  margin: 10px auto;
  @include media-breakpoint-down(md) {
    width: 80%;
    margin: 5px auto 30px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  &__amount {
    height: 39px;
    margin-right: 5px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &--left {
      font-size: 30px;
      font-weight: bold;
      color: $primary;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    &--right {
      .balance {
        font-size: 24px;
        color: #323943;
        font-weight: bold;
      }
      .balance__label {
        font-size: 12px;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }
  &__form {
    width: 50%;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.method__text {
  color: #000;
  font-size: 12px;
  margin-top: -14px;
}

.top-up-history-label {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

.wallet-link {
  width: 50%;
  background: #fff;
  text-align: center;
  padding-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.wallet-link.active {
  border-bottom: 5px solid $primary !important;
}

.pos {
  color: $success;
}

.neg {
  color: $danger;
}

.tmb-date {
  font-size: 14px;
  color: #72767b;
}

.tmb-payment-info {
  font-size: 12px;
}

.tmb-payment-numbers {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tmb-payment-number-bal,
.tmb-payment-number-current {
  font-size: 12px;
  span {
    font-size: 20px;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    display: block;
  }
  button {
    @include media-breakpoint-down(sm) {
      margin-top: 5px;
      width: 100%;
    }
  }
}

.balance__label .wallet-widget {
  .wallet-widget__inner--right {
    margin-left: 85px;
    @include media-breakpoint-down(sm) {
      margin-left: 0px;
    }
  }
}
