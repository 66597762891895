.news-section {
  width: 60%;
  margin: 100px auto 0;
  &--landing {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 20px;
    margin: 90px auto 0;
  }
  &--inside {
    margin: 12px auto 0;
  }
}

.videos-section {
  width: 100%;
  margin: 100px auto 0;
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 20px;
    margin: 90px auto 0;
  }
  &--inside {
    margin: 12px auto 0;
  }
}

.sec-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  &__item {
    margin: 0 10px;
    a {
      color: #000;
    }
    &--logo {
      width: 150px;
    }
  }
}

.article-list {
  margin-top: 30px;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}

.news-section--inside {
  .article-list {
    margin-top: 10px !important;
  }
}

.article {
  overflow-wrap: break-word;
  margin-bottom: 50px;
  &__title {
    overflow-wrap: break-word;
    font-weight: bolder;
    font-size: 30px;
  }
  &__banner {
    margin-top: 25px;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      margin-top: 12px;
    }
    img {
      width: 100%;
    }
  }
  &__date {
    margin-top: 5px;
    font-size: 14px;
    color: grey;
  }

  &__body {
    text-align: justify;
    margin: 20px 0;
    white-space: pre-line;
  }
}

.article-end {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.blue-border {
  border: 2px solid $wedgewood;
}

.videos-container {
  display: flex;
  margin-top: 20px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.video-play {
  width: 60%;
  margin-right: 20px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.video-main-title {
  overflow-wrap: break-word;
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

.video-list {
  @include border-blur;
  padding: 10px;
  width: 40%;
  height: 500px;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .video-list-item {
    &:hover {
      background: #f2f2f2;
    }
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid grey;
    @include media-breakpoint-down(md) {
      display: block;
    }
    .video-thumbnail {
      width: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .video-details {
      overflow-wrap: break-word;
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .video-details-title {
        overflow-wrap: break-word;
        // margin-top: 20px;
        font-weight: bold;
      }
      .video-details-date {
        font-size: 13px;
        overflow-wrap: break-word;
      }
    }
  }
}

.back-to-news {
  a {
    text-decoration: none;
    font-weight: bold;
    text-align: center;
  }
}

.react-player {
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;

}
// landing page
.profile-view-2 {
  min-height: 450px;
  height: 450px;
  margin: 0 24px;
  background-color: #000;
  @include media-breakpoint-down(md) {
    min-height: 0;
  }
  @include media-breakpoint-down(sm) {
    height: 200px;
  }
} 

// custom reactPlayer config for individual components

// /videos page
.video-player { 
  min-height: 360px;
  height: 360px;
  background-color: #000;
  @include media-breakpoint-down(md) {
    min-height: 250px;
    height: 250px;
  }
  @include media-breakpoint-down(sm) {
    // min-height: 0;
    height: 230px;
  }
}

// /news page
.news-landing, .news-landing2 {
  min-height: 325px;
  height: 325px;
  background-color: #000;
  @include media-breakpoint-down(md) {
    height: 230px;
  }
}

// /articles
.news-container, .news-container2 {
  @include media-breakpoint-down(lg) {
    min-height: 0;
  }

  @include media-breakpoint-down(sm) {
    height: 230px;
  }

  .react-player {
    @include media-breakpoint-down(md) {
      height: 300px;
    }
  }
}

// /articles/:id
.single-container, .single-container2 {
  @include media-breakpoint-down(lg) {
    min-height: 0;
  }
  
  @include media-breakpoint-down(sm) {
    height: 230px;
  }

  .react-player {
    @include media-breakpoint-down(md) {
      height: 300px;
    }
  }
}

// for youtube videos
.react-player iframe {
  width: 100% !important;

  @include media-breakpoint-up(sm) {
    min-height: 200px !important;
  }

  @include media-breakpoint-up(md) {
    min-height: 350 !important;
  }

  @include media-breakpoint-up(lg) {
    min-height: 450px !important;
  }

  @include media-breakpoint-up(xl) {
    min-height: 600px !important;
  }
}

.covid-alert {
  color: #dd3544;
  text-align: center;
  margin-top: 10px;
  border: 2px solid #dd3544;
  padding: 10px;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}

.headlines {
  margin-top: 30px;
}

.main-headline {
  // margin-top: 20px;
  position: relative;
  .news-tag {
    position: absolute;
    top: 0;
    left: 20;
    background-color: #dd3544;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 2px 20px;
  }
  &--img {
    img {
      object-fit: cover;
      // height: 350px;
      width: 100%;
    }
  }
}

.featured-news-content {
  text-align: justify;
  font-size: 13px;
  // overflow-wrap: break-word;
  word-break: break-all;
  padding: 0;
}

.features-articles-list {
  width: 100%;
}

.other-headline {
  &-list {
    display: flex;
  }
  &-item {
    padding: 10px;
    .other-thumb {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }
  &-title {
    overflow-wrap: break-word;
    a {
      color: unset;
    }
    font-weight: bold;
    line-height: 1;
  }
}

.sec-banner {
  font-size: 25px;
  color: #4c6085;
  font-weight: bolder;
  position: relative;
  width: 100%;
  &:before {
    content: "";
    width: 73%;
    position: absolute;
    border: 2px solid #4c6085;
    top: 50%;
    right: 0;
    @media only screen and (max-width: 992px) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.more-news {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  a {
    margin: 0 auto;
    border: 2px solid #4c6085;
    padding: 15px;
    text-decoration: none;
    font-weight: bold;
  }
}

.view-more {
  a {
    text-decoration: none;
  }
}
