// ==========================================================================
// $Shame
// @see http://csswizardry.com/2013/04/shame-css/
// Thanks https://github.com/heroheman/shepherd/blob/master/sass/_shame.scss
// ==========================================================================

// ==========================================================================
// because hacks happen.
//
// be very specific about what each piece of code is doing, and
// how to better fix it later
// ==========================================================================

// Try:  $ git blame _shame.scss

// Rules:
// ---------------
//   1. If it’s a hack, it goes in _shame.scss.
//   2. Document all hacks fully:
//   3. What part of the codebase does it relate to?
//   4. Why was this needed?
//   5. How does this fix it?
//   6. How might you fix it properly, given more time?
//   7. Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
//   8. Try and clean _shame.scss up when you have some down time.

// Example:
// ---------------
// Nav specificity fix.

// Someone used an ID in the header code (`#header a{}`) which trumps the
// nav selectors (`.site-nav a{}`). Use !important to override it until I
// have time to refactor the header stuff.

// .site-nav a { color:#BADA55!important; }

.modal-lg {
  max-width: 72% !important;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    max-width: 90% !important;
    margin-top: 30px;
  }
}

.modal-md {
  max-width: 50% !important;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    max-width: 90% !important;
    margin-top: 30px;
  }
}

.select-transaction-modal {
  max-width: 60% !important;
  margin: 0 auto;
}

@include media-breakpoint-down(sm) {
  .select-transaction-modal {
    max-width: 90% !important;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1079.98px) {
  .select-transaction-modal {
    max-width: 80% !important;
    margin: 0 auto;
    .transaction-description {
      font-weight: bold;
      font-size: 12px !important;
    }
    .clear-modal .modal-close {
      > span {
        font-size: 1.7em !important;
      }
    }
    .back-button {
      > span {
        font-size: 22px !important;
      }
    }
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: $color-primary !important;
}
.bg-light {
  background-color: #f7faff !important;
}
.bg-primary {
  background-color: $color-primary !important;
}

.border-primary {
  border: 4px solid #9bc03c !important;
}

.info-section {
  @include media-breakpoint-down(sm) {
    display: none !important;
    background-color: transparent;
  }
}

@include media-breakpoint-down(sm) {
  .login-btn {
    margin-left: 0 !important;
  }
}
